
@font-face {
    font-family: 'titillium';
    src: url('/fonts/titilliumweb-regular-webfont.eot');
    src: url('/fonts/titilliumweb-regular-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-regular-webfont.woff') format('woff'), url('/fonts/titilliumweb-regular-webfont.ttf') format('truetype'), url('/fonts/titilliumweb-regular-webfont.svg#titillium_webregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'titillium';
    src: url('/fonts/titilliumweb-bold-webfont.eot');
    src: url('/fonts/titilliumweb-bold-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-bold-webfont.woff') format('woff'), url('/fonts/titilliumweb-bold-webfont.ttf') format('truetype'), url('/fonts/titilliumweb-bold-webfont.svg#titillium_webbold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'titillium';
    src: url('/fonts/titilliumweb-italic-webfont.eot');
    src: url('/fonts/titilliumweb-italic-webfont.eot?#iefix') format('embedded-opentype'), url('/fonts/titilliumweb-italic-webfont.woff') format('woff'), url('/fonts/titilliumweb-italic-webfont.ttf') format('truetype'), url('/fonts/titilliumweb-italic-webfont.svg#titillium_webbold') format('svg');
    font-style: italic;
}

/*Ikoner*/
@font-face {
    font-family: 'icons';
    src: url('/fonts/fuauIntranet.eot');
    src: url('/fonts/fuauIntranet.eot?#iefix') format('embedded-opentype'), url('/fonts/fuauIntranet.ttf') format('truetype'), url('/fonts/fuauIntranet.woff') format('woff'), url('/fonts/fuauIntranet.svg#fuauIntranet') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: rgba(162, 162, 162, 0.75);
}

.icon-icon_9094:before {
    content: "\e614";
}

.icon-decline-icon:before {
    content: "\e612";
}

.icon-accept-icon:before {
    content: "\e613";
}

.icon-contract:before {
    content: "\e611";
}

.icon-Favorite-icon:before {
    content: "\e610";
}

.icon-Create-multi-icon:before {
    content: "\e60f";
}

.icon-Folder-closed-icon:before {
    content: "\e60b";
}

.icon-Img-icon:before {
    content: "\e60c";
}

.icon-Folder-open-icon:before {
    content: "\e60d";
}

.icon-Doc-icon:before {
    content: "\e60e";
}

.icon-Cal-icon:before {
    content: "\e60a";
}

.icon-Mail-icon:before {
    content: "\e609";
}

.icon-Edit-icon:before {
    content: "\e606";
}

.icon-Delete-icon:before {
    content: "\e607";
}

.icon-Close-icon:before {
    content: "\e608";
}

.icon-User-icon:before {
    content: "\e600";
}

.icon-Search-icon:before {
    content: "\e601";
}

.icon-List-link-icon:before {
    content: "\e603";
}

.icon-List-economy-icon:before {
    content: "\e602";
}

.icon-List-content-icon:before {
    content: "\e604";
}

.icon-Create-icon:before {
    content: "\e605";
}

.icon-comment:before {
    content: "\e615";
}

.icon-warning:before {
    content: "\e616";
}

.icon-paperclip:before {
    content: "\e617";
}

.icon-arrowUp:before {
    content: "\e61a";
}



@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: 'titillium';
        src: url('/fonts/titilliumweb-regular-webfont.svg') format('svg');
        font-weight: normal;
        font-style: normal;
    }

    @font-face {
        font-family: 'titillium';
        src: url('/fonts/titilliumweb-bold-webfont.svg') format('svg');
        font-weight: bold;
        font-style: normal;
    }

    @font-face {
        font-family: 'titillium';
        src: url('/fonts/titilliumweb-italic-webfont.svg') format('svg');
        font-style: italic;
    }

    @font-face {
        font-family: 'icons';
        src: url('/fonts/fuauIntranet.svg#fuauIntranet') format('svg');
        font-weight: normal;
        font-style: normal;
    }
}

.filterHeader {
    font-size: 14px;
    margin-bottom: 7px;
    line-height: 1;
}

button.filterHeader.small {
    color: #12779a;
    padding: 0;
    margin-bottom: 0;
    padding: 10px 60px;
}

button.disabled {
    opacity: 0.4;
    cursor: default;
}

.sectionWrapper {
}

    .sectionWrapper .section {
        overflow: hidden;
        margin-bottom: 20px;
    }

        .sectionWrapper .section.hide {
            display: none;
        }

.sectionButton {
    float: right;
    padding: 16px 30px 16px;
    font-size: 0.75rem;
    color: #555;
}

.sectionTitle {
    padding: 10px;
    display: block;
}

body {
    min-width: 1000px;
    font-family: 'titillium';
}

.row.spacerTop {
    margin-top: 20px;
}

.row.spacerDown, .modalRow.spacerDown {
    margin-bottom: 20px;
}

.noMargin {
    margin: 0!important;
}

select {
    font-family: Arial!important;
}

input.error {
    border: #c11e1e 2px solid;
}

input.readonly, select.readonly, input.readonly:focus, select.readonly:focus {
    background: #eee;
    color: #888;
    border-color: #ccc;
    -webkit-box-shadow: 0 0 0px #999999;
    -moz-box-shadow: 0 0 0px #999999;
    box-shadow: 0 0 0px #999999;
}

.inputWrapper {
    position: relative;
}

    .inputWrapper.withParagraph p {
        padding: 0.3rem 0;
    }

        .inputWrapper.withParagraph p.success {
            font-weight: 700;
        }

.checkboxWrapper {
    overflow: hidden;
    margin-bottom: 5px;
}

    .checkboxWrapper input[type="checkbox"] {
        margin-bottom: 0;
        float: left;
    }

    .checkboxWrapper label {
        position: relative;
        top: -4px;
    }
label.slim {
    font-weight:100;
    }
    .checkboxWrapper p.description {
        margin: -5px 0 0 20px;
    }

.intraListWrapper {
    margin: 20px;
}

.currentItemDataHolder.list {
    margin-top: 20px;
}

.intraListWrapper tr:hover td {
    background: #D6F2FA;
}

.productlistKeyAccountSales {
    text-align: center;
}

#productlistColumnPicker .checkboxWrapper label {
    top: -6px;
}

.productlistWaitinglist {
    text-align: center;
}

.productlistVisibleId {
    white-space: nowrap;
}

.productlistPrice, .productlistStudentPrice, .productlistProfit, .productlistSales{
    text-align:right;
}

.productlistSelect input, .checkboxOnList {
    margin: 3px 0 0!important;
}

#productSearchTable td:first-child, #productSearchTable th:first-child {
    padding: 2px 5px;
}

th.productlistStatusSelect input {
    position: relative;
    top: 8px;
}

table.noBorders, table.noBorders td {
    border: 0;
}

#toggleAdvancedSearch {
    margin-top: -10px;
    display: block;
    margin-left: 10px;
    font-size: 0.9rem;
}

.listFilterWrapper {
    float: right;
    margin-right: 20px;
}

.datePickBtn {
    position: absolute;
    right: 10px;
    bottom: 7px;
}

.dateWrapper .datePickBtn {
    bottom: 22px;
}

.dateWrapper .description {
    position: absolute;
}

.searchBtn {
    /*position: absolute;
    right: 15px;
    bottom: 3px;*/
    font-size: 22px;
    padding: 12px!important;
}

    .searchBtn .icon-Search-icon {
        /*padding-left: 10px;
    padding-right:0!important;*/
    }

    .searchBtn.large {
        bottom: 11px;
    }

.liRow {
    overflow: hidden;
    position: relative;
}

.liColumn {
    float: left;
    padding: 15px;
}


.fl {
    float: left;
}

.center {
    text-align: center;
}

.right, .textRight {
    text-align: right;
}

.textleft {
    text-align: left;
}

.nolist {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
}

.smalllist {
    font-size: 0.75rem;
}

    .smalllist li {
        border-bottom: 1px dashed #ddd;
        overflow: hidden;
        padding: 5px 0;
    }

a {
    color: #12779a;
}

    a:hover {
        color: #224175;
    }

#mainHeader {
    background: #298ea9; /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIyJSIgc3RvcC1jb2xvcj0iIzI5OGVhOSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9Ijk5JSIgc3RvcC1jb2xvcj0iIzExNzY5OSIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
    background: -moz-linear-gradient(top, #298ea9 2%, #117699 99%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(2%,#298ea9), color-stop(99%,#117699)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top, #298ea9 2%,#117699 99%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top, #298ea9 2%,#117699 99%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top, #298ea9 2%,#117699 99%); /* IE10+ */
    background: linear-gradient(to bottom, #298ea9 2%,#117699 99%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#298ea9', endColorstr='#117699',GradientType=0 ); /* IE6-8 */
}

#primaryLogo {
    display: block;
    padding: 10px 0;
}

#mainContentWrapper {
    background: #fff;
    padding: 40px 0;
}


#primaryNavigationWrapper {
    background-color: #0f6481;
}

#primaryNavigation .navigationItem {
    float: left;
    margin-top: 15px;
}

.navigationItemLink {
    display: block;
    padding: 10px 20px 7px;
    font-size: 0.85rem;
    margin-left: 10px;
    background: #12779a;
    color: #fff;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.first .navigationItemLink {
    margin-left: 0;
}

.navigationItemLink.selected, .navigationItemLink.selected:hover {
    background-color: #eaeaea;
    color: #0a234c;
}

.navigationItemLink:hover {
    background: #298ea9;
    color: #fff;
}

#secondaryNavigationWrapper {
    background-color: #eaeaea;
    min-height: 10px;
}

    #secondaryNavigationWrapper .navigationItemLink {
        background: none;
        text-align: center;
        float: left;
        color: #12779a;
    }



input.primarySearch {
    font-size: 24px;
    padding: 0.75rem 0.5rem;
}

.primarySearchSumRowPrice {
    text-align: right;
    padding-top: 10px;
    padding-bottom: 10px;
}

.primarySearchSumRowPriceLabel {
    padding-right: 20px;
}

table {
    background: white;
    margin-bottom: 1.25rem;
    border-top: solid 1px #dddddd;
    width: 100%;
}

thead.whiteBackground {
    background: #fff;
    border-bottom: 1px solid #e5e5e5;
}

table thead,
table tfoot {
    background: whitesmoke;
    font-weight: bold;
}

    table thead tr th,
    table thead tr td,
    table tfoot tr th,
    table tfoot tr td {
        padding: 0.2em 10px;
        font-size: 0.875rem;
        color: #222222;
        text-align: left;
    }

    table thead tr th {
        padding: 10px;
        font-weight: 100;
    }

table tr th,
table tr td {
    padding: 0.2em 10px;
    color: #222222;
    font-size: 14px;
    border-bottom: solid 1px #dddddd;
}

table tr.alt td, table tr:nth-of-type(even) td {
    background: #f0f0f0;
}

table [class^="icon-"], .productOptionsHeader [class^="icon-"] {
    font-size: 22px;
}

table thead tr th,
table tfoot tr th,
table tbody tr td,
table tr td,
table tfoot tr td {
    display: table-cell;
    line-height: 1.125rem;
}


.button .nextToSearch {
    padding: 15px 40px;
}

.button.disabled {
    opacity: 0.4;
}

.button.secondary [class^="icon-"], [class*=" icon-"], button.secondary [class^="icon-"], [class*=" icon-"] {
    padding-right: 10px;
    position: relative;
    top: 2px;
}

.button.secondary.small [class^="icon-"], [class*=" icon-"], button.secondary.small [class^="icon-"], [class*=" icon-"] {
    padding-right: 3px;
}

.button.primary [class^="icon-"], [class*=" icon-"], button.primary [class^="icon-"], [class*=" icon-"] {
    color: #fff;
    padding-right: 10px;
    position: relative;
    top: 2px;
}

/* PL = ProductList */
.productlistPublished, .productlistContentStatus{    
    text-align:center;
}
    .productlistPublished span {
        padding-right:0;
        top:-1px;
    }

.productlistFirstDate, .productlistPublishedFrom, .productlistPublishedTo, .productlistRegistrationStart, .productlistRegistrationEnd {
    text-align: right;
    white-space: nowrap;
}

.productlistAttendance, .productlistRegistrationOpen, .productlistHasImage {
    text-align: center;
}

.productlistAvailable {
    text-align: center;
}

.productlistName {
    min-width: 305px;
}

#productSearchTableApproved .productlistName, #productSearchTableApproved .productlistName {
    min-width: initial;
}

.productlistStatus {
    text-align: center;
}

td.PL_c2.productlistEconomyStatus {
    padding: 0.2em 0;
    text-align:center;
}

.productlistEvents {
    text-align: center;
}

/* OL = OrderList */
.orderslistProcessed [class^="icon-"], .orderslistProcessed [class*=" icon-"] {
    font-size: 14px;
}

#ordersSearchTable .cancled td {
    opacity: 0.4;
    text-decoration: line-through;
}

    #ordersSearchTable .cancled td.OL_c2 {
        opacity: 1;
        text-decoration: none;
    }

.orderslistTransitionNumber {
    text-align: center;
}

.orderslistDate {
    white-space: nowrap;
    text-align: right;
}

.orderslistAmount {
    white-space: nowrap;
    text-align: right;
}

.orderslistProducts {
    text-align: center;
}
/* OrdersDetail */

#orderTicketContent {
    height: 300px;
}




/* ODL = OrderDetailList */
.ODL_c1 {
}

.ODL_c2 {
    text-align: center;
}

.ODL_c3 {
}

.ODL_c5 {
    text-align: right;
}

.ODL_c6 {
    text-align: right;
}

.ODL_c7 {
    text-align: center;
}

table td {
    background: #fff;
}

#orderDetailOrderlinesTable .orderline.disabled a, .orderline.disabled td, .optionline.disabled td {
    color: rgba(0,0,0,0.5);
    text-decoration: line-through;
}

#orderDetailOrderlinesTable .discountline td {
    background: #fff;
}

#orderDetailOrderlinesTable.discountline .description {
    font-size: 12px;
    font-style: normal;
}

    #orderDetailOrderlinesTable .discountline .description.black {
        color: #000;
        padding: 0 10px 0 5px;
    }

.optionlineTag {
    margin: 0;
    color: #bbb;
    font-size: 14px;
    padding-left: 10px;
}

#orderDetailOrderlinesTable td {
    background: #fff;
}

#orderDetailOrderlinesTable .subtotalLine td {
    background: #eee;
}

#orderDetailOrderlinesTable .subtotalLine .ODL_c3, .subtotalLine .ODL_c6 {
    font-weight: 100;
    color: #444;
}

#orderDetailOrderlinesTable tr.totalLine td {
    border-top: 2px solid #bbb;
}
/* CL = CourseList */
#courselist [class^="icon-"], #courselist [class*=" icon-"] {
    color: #12779a;
}

.courselistHeaderWrapper {
    overflow: hidden;
    font-weight: 700;
    border-top: 1px solid #ddd;
}

.CL_rowInner {
    background: #f9f9f9;
    overflow: hidden;
    font-size: 0.9rem;
}

    .CL_rowInner .CL_ToolColumn {
        width: 21%;
    }

.CL_row.cancelled .CL_rowInner {
    background-color: #f5c7c7;
}

.CL_row {
    border: 1px solid #ccc;
    margin-bottom: 10px;
}

    .CL_row .liColumn {
        padding: 5px 15px;
    }

.CL_rowInner.headerBar {
    background: #eaeaea;
    border-bottom: 1px solid #ddd;
}


.productDetailCourseLabel {
    margin: 0;
}

.CL_c1 {
    width: 10%;
    text-align: center;
}

.CL_c2 {
    width: 10%;
    text-align: center;
}

.CL_c3 {
    width: 22%;
}

.CL_c4 {
    width: 22%;
}

.CL_c5 {
    width: 15%;
}

.CL_c6 {
    width: 16%;
    padding: 0;
    text-align: right;
}

.soldOut {
    font-weight: 700;
    color: #c11e1e;
}

.courseButtonWrapper {
    overflow: hidden;
}

.courseStatusWrapper {
    color: #666;
    font-size: 12px;
    line-height: 10px;
    text-align: right;
}

/* CEL = CourseEventList */


.CEL_row {
    border-top: 1px dashed #dddddd;
}

    .CEL_row .location .locationName {
        font-size: 0.9rem;
        display: block;
        line-height: 1rem;
    }

    .CEL_row .title {
        display: block;
        font-weight: 700;
        font-size: 0.9rem;
    }

    .CEL_row .description {
        color: #888;
        font-style: normal;
        font-size: 0.8rem;
        display: block;
        margin: 0;
        line-height: 1rem;
    }

    .CEL_row p {
        margin: 0;
        font-size: 0.9rem;
    }

.CEL_c1 {
    width: 20%;
}

    .CEL_c1 .title {
        color: #777;
    }

.CEL_c2 {
    width: 22%;
}

.CEL_c3 {
    width: 22%;
}

.CEL_c4 {
    width: 26%;
}

.CEL_c5 {
    padding: 0;
    width: 10%;
    text-align: right;
}

    .CEL_c5 [class^="icon-"], .CEL_c5 [class*=" icon-"] {
        color: rgba(204, 204, 204, 0.75);
    }


.CEL_employeeItem[data-status="1"] {
    cursor: default;
    color: #333;
}

    .CEL_employeeItem[data-status="1"]:hover {
        color: #333;
    }

.CEL_employeeItem, .CEL_employeeItemContract {
    display: block;
    margin-bottom: 2px;
    font-size: 0.9em;
}

    .CEL_employeeItemContract [class^="icon-"], .CEL_employeeItemContract [class*=" icon-"] {
        font-size: 12px!important;
        position: relative;
        top: 1px;
    }

/*.CEL_employeeItemContract[data-status="1.5"]:hover {
        cursor:default!important;
        color:#12779a;
    }*/
.CEL_employeeItemName {
    display: block;
}

.CEL_employeeItemStatus {
    font-size: 0.75rem;
    margin-top: -7px;
    color: #888;
}

    .CEL_employeeItemStatus.attention {
        color: #73732E;
    }

    .CEL_employeeItemStatus.error {
        color: #c60f13;
        padding: 0;
        background: none;
        font-weight: 700;
        margin-top: -7px;
    }

.CEL_row.cancelled {
    border-left: 2px solid #c11e1e!important;
}

    .CEL_row.cancelled .startDate, .CEL_row.cancelled .endDate {
        text-decoration: line-through;
    }

#employeeRelationModal {
    min-height: 390px;
}

#modalEventCancelReason, #modalCourseCancelReason {
    min-height: 150px;
}

#employeeRelationWrapper {
    margin-top: 10px;
}

    #employeeRelationWrapper label {
        margin: 0;
    }

    #employeeRelationWrapper a {
        display: block;
        margin-bottom: 20px;
    }

#employeeRelationUserResult .noSearchResult {
    padding: 10px;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
}
/* LL = Locationlist */
.LL_c1 {
}

.LL_c2 {
}

.locationlistZipcode {
    text-align: center;
}


/* CAL = Course Attendance List */

.CAL_c1 {
}

.CAL_c2 {
}

.CAL_c3 {
    text-align: center;
}

.CAL_c4 {
}

.CAL_c5 {
    text-align: center;
}

.CAL_c6 {
    text-align: center;
}

.modalPaddingBox {
    padding: 0 15px;
    width: 100%;
}

.modalRow {
    overflow: hidden;
}

#attendanceSearchTableWrapper, .modalContent {
    overflow-y: auto;
    overflow-x: hidden;
    display: block;
}

#attendanceSearchTableWrapper {
    height: 450px;
}

#attendanceSearchTable {
    border-top: 0;
}

    #attendanceSearchTable tbody {
    }

.productRow.cancelled td {
    text-decoration: line-through;
}

.productRow [class^="icon-"] {
    font-size: 1rem;
}

.dateLabel {
    width: 40px;
    display: block;
    float: left;
}

.pageingItem {
    display: block;
    float: left;
    background: #eaeaea;
    padding: 7px;
    min-width: 30px;
    text-align: center;
    margin-right: 5px;
    color: #444;
}

    .pageingItem.selected, .pageingItem.selected:hover {
        background-color: #ccc;
    }

    .pageingItem:hover {
        background-color: #f3f3f3;
        color: #333;
    }

button, .button {
    background-color: transparent;
    border: 0;
    display: inline-block;
    text-align: center;
}

    button.primary, .button.primary,
    button.secondary, .button.secondary {
        padding: 20px 40px;
        width: 100%;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        margin-bottom: 20px;
    }

    button.primary, .button.primary {
        background-color: #12779a;
        color: #fff;
        border: 1px solid #0d627f;
        font-weight: 700;
    }

    button.secondary, .button.secondary {
        background-color: #eaeaea;
        border: 1px solid #dbdbdb;
        color: #555;
    }

    button.small, .button.small {
        padding: 10px 20px;
        font-size: 0.9rem;
        line-height: 1;
    }

#productSearchResult, #orderSearchResult {
    position: relative;
}

#shortDesc {
    height: 100px;
}

#longDesc {
    height: 300px;
}

.primarySearchResults {
    margin-top: 20px;
}

.pagingWrapper {
    overflow: hidden;
    float: left;
}

.pagerToolsWrapper {
    overflow: hidden;
}

.pageSizeSelectorWrapper {
    float: right;
    font-size: 0.9rem;
}

.pageSizeSelector {
    margin-left: 10px;
    width: 75px;
}

/* Loader */
#loaderOverlay {
    background-color: rgba(255,255,255,0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

#loaderWrapper {
    margin: auto;
}

#loading {
    cursor: wait;
    background-color: rgba(0,0,0,0);
    opacity: .8;
    border-radius: 50px;
    margin: 5% auto;
    animation: loading .5s infinite linear;
    -moz-animation: loading .5s infinite linear;
    -webkit-animation: loading .5s infinite linear;
}

.loadingText {
    color: #222323;
    text-align: center;
}

.spin-1 {
    border: 10px solid #117699;
    border-top: 10px solid rgba(0,0,0,0);
    border-left: 10px solid rgba(0,0,0,0);
    width: 50px;
    height: 50px;
}

@keyframes loading {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }

    ;
}

@-moz-keyframes loading {
    0% {
        -moz-transform: rotate(0deg);
    }

    100% {
        -moz-transform: rotate(360deg);
    }

    ;
}

@-webkit-keyframes loading {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }

    ;
}

.box-success {
    background-color: #1ec17a;
    color: #fff!important;
}

.text-success {
    color: #1ec17a!important;
}

.text-attention {
    color: #E68A00!important;
}

.box-attention {
    background-color: #E68A00;
    color: #fff!important;
}

.text-passive {
    color: rgba(162, 162, 162, 0.75)!important;
}

.box-passive {
    background-color: rgba(162, 162, 162, 0.75)!important;
    color: #fff!important;
}




.text-error {
    color: #c11e1e!important;
}

.box-error {
    background-color: #c11e1e!important;
    color: #fff;
}

.success {
    color: #1ec17a;
}

.error {
    color: #c11e1e;
}


/* Edit Product */

.smallText p, .smallText span {
    font-size: 0.85rem;
    margin-bottom: 0;
}


.description.alone {
    text-align: center;
    margin-top: 20px;
}

.description {
    margin: -15px 0 15px 0;
    font-size: 0.75em;
    color: #777;
    font-style: italic;
}

    .description.extraSpace {
        position: relative;
        top: -12px;
        clear: both;
    }

.summaryRow {
    /* background: #f9f9f9; */
    overflow: hidden;
    padding: 10px 0;
    border-bottom: 1px solid #ddd;
}

    .summaryRow.first {
        padding-top: 0;
    }

    .summaryRow .description {
        padding-right: 90px;
    }

    .summaryRow p {
        margin: 0;
    }



label {
    color: #555;
    font-weight: 700;
    line-height: 20px;
}

.currentItemDataHolder h2 {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background: #f9f9f9;
    color: #555;
    font-size: 1.25rem;
    margin-bottom: 20px;
}

.responsiblelistItem p {
    margin: 0;
    line-height: 0.85rem;
}

.responsiblelistItemChange {
    float: right;
    font-size: 0.8rem;
    color: #12779a;
    padding: 7px;
}

.textareaWrapper {
    margin-bottom: 15px!important;
}

table.smallPadding th, table.smallPadding td {
    padding: 5px 15px;
}

table.smallPadding .courselistButton {
    padding: 10px;
}

#productCategoryPicker.disabled {
    opacity: 0.35;
}

.productCategoryPrimaryBtn.selected i, .productAreaPrimaryBtn.selected i {
    color: #d7d325;
}


.PCL_c3 {
    text-align: right;
}


#contractRecipients {
    margin-bottom: 0;
}

.attachmentModallist .checkboxWrapper {
    width: 32px;
}

.statusTitle {
    float: left;
    width: 75px;
}

    .statusTitle p {
        font-weight: 700;
    }

.statusStatus {
    font-weight: 700;
}

.statusIcon {
    float: left;
    margin-right: 5px;
    position: relative;
    top: 3px;
    padding-right: 0;
}

.statusWrapper {
    border-bottom: 1px solid #eee;
    overflow: hidden;
}

.statuslist {
    border-top: 1px solid #eee;
}

.statusAuthor, .statusLinkText {
    font-size: 0.7rem!important;
    line-height: 0.7rem;
}

.statusDescriptionWrapper {
    padding: 5px 0;
    overflow: hidden;
}
/* Edit Product - END */


/* ALERT BOXES */
#statusMsgHolder {
    bottom: 20px;
    left: 50%;
    margin-left: -485px;
    position: fixed;
    width: 100%;
    max-width: 970px;
    z-index: 30030;
}

.statusMsg {
    -webkit-border-radius: 3px;
    border-radius: 3px;
    margin-bottom: 1.25rem;
    padding: 0.875rem 2.5rem 0.875rem 0.875rem;
    border-style: solid;
    border-width: 1px;
    width: 100%;
    max-width: 970px;
    font-weight: 700;
    font-size: 1em;
    display: none;
    position: relative;
}

    .statusMsg.show {
        display: block;
    }

    .statusMsg.success {
        background-color: #1ec17a;
        border-color: #14A666;
        color: white;
    }

        .statusMsg.success a {
            color: white;
            text-decoration: underline;
        }

    .statusMsg.error {
        background-color: #c11e1e;
        border-color: #a61414;
        color: white;
    }

    .statusMsg.attention {
        background-color: #fff7a0;
        border-color: #d8c600;
        color: #626252;
    }

        .statusMsg.attention .statusMsgClose {
            color: #626252;
        }

.statusMsgClose {
    display: block;
    float: right;
    color: rgba(255,255,255,0.9);
    margin-top: -3px;
    position: absolute;
    top: 16px;
    right: 16px;
    margin-right: -2px;
    font-size: 1.3rem;
}

    .statusMsgClose:hover {
        color: rgba(255,255,255,1);
    }

.economyStatusWrapper {
    overflow: hidden;
    font-size: 0.85rem;
    padding: 5px;
    float: left;
}

.largeStatusIcon {
    font-size: 2rem;
    padding: 14px 8px;
    width: 50px;
    text-align: center;
}

    .largeStatusIcon span {
        font-size: 2rem;
    }

    .largeStatusIcon.proof, .largeStatusIcon.published {
        float: left;
        margin-right: 10px;
    }

        .largeStatusIcon.proof span, .largeStatusIcon.published span {
            color: #fff;
        }

#publishedButNoCategory {
    display: block;
    font-weight: 100;
    font-size: 12px;
    color: #333;
}

    #publishedButNoCategory.hide {
        display: none;
    }

.economyStatusButtonLarge {
    display: block;
    font-size: 2rem;
    padding: 14px 8px;
}

    .economyStatusButtonLarge .icon-List-economy-icon {
        display: block;
        color: #fff!important;
    }

.economyStatusButton {
    display: block;
}


    .economyStatusButton[data-status="approved"] {
        cursor: default;
    }

.economyStatusText {
    font-weight: 700;
}

.economyStatusWrapper .icon-List-economy-icon {
    float: left;
    margin-right: 10px;
    position: relative;
    top: 3px;
}

.economyStatusWrapper .statusAuthor {
    margin: -3px 0;
    display: block;
    color: #555;
}

    .economyStatusWrapper .statusAuthor.cancelledAuthor {
        margin-top: 5px;
    }

.courselistButton {
    font-size: 1.15rem;
    margin-bottom: 0;
    padding-bottom: 0;
    padding: 8px 10px;
    float: right;
}

#createCourse {
}

#courseUpdateForm {
    margin-top: 20px;
    overflow: hidden;
}

.editCourseModal {
    min-height: 500px;
}

#courseMessageContent {
    height: 220px;
    resize: vertical;
}

.dateWrapper, .timeWrapper {
    float: left;
}

.dateWrapper {
    width: 80%;
}

.timeWrapper {
    width: 20%;
}

.locationAdressWrapper, .employeeAddressWrapper {
    border: 1px dashed #aaa;
    border-top: 0;
    color: #aaa;
    padding: 0.5rem;
    margin-bottom: 15px;
}

.locationDescription {
    margin: 0;
    font-size: 0.8rem;
}

#courseEventAddLocation, #courseEventAddEmployee {
    margin-top: 20px;
}

#addEquipmentToLocation {
    width: 45%;
    margin-top: 10px;
}

#equipmentlist a {
    color: #333;
    display: block;
}

    #equipmentlist a .icon-decline-icon {
        float: right;
        padding: 2px 5px;
    }

#courseEventLocationSelector, #courseEventEmployeeSelector {
    font-family: Arial;
    height: 255px;
}

#courseEventDescription {
    min-height: 200px;
}

.courseEventAddedEmployeeInputWrapper {

    padding: 10px 0;
    overflow: hidden;
}

.courseEventContractComment{
    border-bottom: 1px dashed #bbb;
    padding: 5px 0 10px;
}

.addedEmployeeWrapper {
    position: relative;
}

.productCourseRemoveEmployeeBtn {
    position: absolute;
    top: 8px;
    right: 5px;
}

.courseEventAddedEmployeeAddressToggleButton, .courseEventGeoLink {
    font-size: 0.8rem;
    display: block;
    padding: 10px 0;
}


/* Product Options */
.productOptionEdit {
    float: right;
}

.productOptionsHeader {
    padding: 0.5em 10px;
    background: #eaeaea;
    overflow: hidden;
    color: #555;
}

.productsOptionHeaderTitle {
    display: block;
    font-size: 18px;
    line-height: 18px;
    font-weight: 700;
    padding: 0;
}

.productsOptionHeaderDescription {
    font-size: 12px;
    display: block;
    line-height: 12px;
}

.productOptionsTable {
    width: 100%;
}

    .productOptionsTable td {
        padding-top: 5px;
        padding-bottom: 5px;
    }

.productOptionTitle {
    font-weight: 700;
    color: #555;
    display: block;
}

.productOptionPrice {
    display: block;
    padding-top: 5px;
}

.optionsButton {
    float: right;
    padding-left: 20px;
    position: relative;
}

    .optionsButton i[class^="icon-"] {
        font-size: 18px;
    }

.productOptionDescription {
    color: #8a8a8a;
}

.optionsButton i {
    color: #12779a;
}

.productOptionsHeader .optionsButton {
    padding: 5px 8px 0;
}

/* Product Options End */

/* Product Course Event list item */
#productCourseEventLocationlistWrapper, #productCourseEventEmployeelistWrapper, #productCourseEventEmployeelist {
    position: relative;
}


.productCourseEventlistItem {
    font-size: 0.8rem;
    border-bottom: 1px #eee solid;
    overflow: hidden;
}

.courseEventSelectedItemTitle {
    margin-bottom: 10px;
}

#productWaitlistMessage {
    height: 200px;
    resize: vertical;
}

.productCourseWaitlistWrapper {
    max-height: 320px;
    overflow-y: auto;
}

.waitingCounter {
    color: #fff;
    position: absolute;
    top: 0;
    left: 0;
    font-weight: 700;
    font-size: 10px;
    background: rgba(0,0,0,0.5);
    padding: 2px 5px;
    border-radius: 80px;
    line-height: 12px;
}

.productDetailCourseEventModallist {
    border: 1px solid #ccc;
    height: 320px;
    overflow: auto;
}

.productDetailCourseEconomyStatusIcon {
    float: left;
    margin: 0 0.4rem 0 -0.95em;
}


.productCourseEventLocationlistItem.booked {
    background-color: #FFD4D4;
    border-bottom: 1px solid #ddd;
}

.productCourseEventLocationlistItem.toSmall {
    background-color: #fff7a0;
    border-bottom: 1px solid #ddd;
}

    .productCourseEventLocationlistItem.toSmall.booked {
        background-color: #fff7a0;
    }

        .productCourseEventLocationlistItem.toSmall.booked .productCourseEventLocationlistItemContent {
            border-right: 75px solid #FFD4D4;
        }

.productCourseEventLocationlistItem button, .productCourseEventEmployeelistItemContent button {
    color: #a0a0a0;
    padding: 8px 15px 7px;
    border-bottom: 1px solid #aaa;
}

.productCourseEventLocationlistItemContent {
    padding: 5px;
}

.productCourseEventLocationlistItemCapacity {
    display: block;
}

.productCourseEventLocationlistItemButton, .productCourseEventEmployeelistItemButton {
    background-color: #eaeaea;
    border-left: 1px solid #dbdbdb;
    color: #555;
    float: right;
    font-size: 2rem;
}

.courseEventLocationsCreateShortcut {
    font-size: 0.8rem;
    padding: 15px 0;
    display: block;
}

.courseEventSelectedItemWrapper {
    background: #f9f9f9;
    border: 1px solid #ddd;
    padding: 10px;
    position: relative;
    overflow: hidden;
    margin-bottom: 10px;
}

.productCourseEventLocationlistItemEmpty {
    text-align: center;
    padding: 20px;
    font-size: 0.75rem;
    color: #444;
}

#courseEventAddedEmployeeList {
    height: 374px;
    overflow: auto;
    /* border: 1px solid; */
    padding-right: 5px;
}

.courseEventAddedEmployeeTitleWrapper {
    width: 60%;
    float: left;
}

.courseEventAddedEmployeeQuantityWrapper {
    width: 40%;
    padding-left: 0.75rem;
    float: left;
    overflow: hidden;
}

input.courseEventAddedEmployeeQuantity {
    width: 30%;
    float: left;
    text-align: right;
}

.courseEventQuantityDescription {
    float: left;
    padding: 10px;
    font-style: italic;
    color: #aaa;
    font-weight: 700;
}

/* Product Course Event list item - End */


.teacherWrapper {
    padding: 0.5rem;
    margin-top: -6px;
    margin-bottom: 15px;
}

.ui-datepicker-next:hover, .ui-datepicker-next {
    background: url('/image/jqueryUI/next-arrow.png') no-repeat center;
    border: 0;
}

.ui-datepicker-prev:hover, .ui-datepicker-prev {
    background: url('/image/jqueryUI/prev-arrow.png') no-repeat center;
    border: 0;
}
/* COLORBOX CUSTOM */

#cboxOverlay {
    background: rgba(0,0,0,0.75);
}

.colorboxWrapper {
    display: none;
}

#cboxLoadedContent {
    background: #fff;
    padding: 0;
}

#cboxLoadingOverlay {
    background: #fff;
}

#cboxContent {
    background: #fff;
    -webkit-border-radius: 15px;
    border-radius: 15px;
}

.colorboxCloseWrapper {
    float: left;
    width: 100px;
}

.colorboxButtonWrapper {
    width: 100px;
    float: right;
}
/* Websites / Categories */
#websiteslist {
}

.websiteItem {
    overflow: hidden;
}

    .websiteItem .websiteItemDescriptionWrapper {
        float: left;
    }

        .websiteItem .websiteItemDescriptionWrapper .description {
            margin: -5px 0 0;
        }

.listWithSublist {
    background: #efefef;
    border-bottom: 1px solid #ddd;
}

    .listWithSublist li.even {
        background-color: #e0e0e0;
    }

    .listWithSublist .header {
        padding: 15px;
        display: block;
        border-top: 1px solid #ddd;
    }

        .listWithSublist .header.haveChildren {
            border-bottom: 1px dashed #ccc;
        }

.sublist {
    background: #fff;
}

    .sublist li.even {
        background: #f9f9f9;
    }

    .sublist li {
        padding: 10px 15px 10px 30px;
        border-top: 1px solid #eee;
    }

.websiteHeaderWrapper .websiteCategoryButtonWrapper {
    padding: 18px 15px 13px;
}

.websiteCategoryButtonWrapper {
    float: right;
}

.websiteOpenLink {
    display: block;
    float: left;
    margin-right: 5px;
}


/* FILEDB - START */
.fileDBSearchWrapper {
    float: left;
    min-width: 400px;
}

.fileDBButtonWrapper {
    overflow: hidden;
    float: right;
}

#fileDatabaseWrapper {
    overflow: auto;
    min-width: 1000px;
}

.fileDBNavigationWrapper {
    float: left;
    position: relative;
    width: 15%;
    min-width: 150px;
    min-height: 500px;
    background-color: #ddd;
    border-right: 1px solid #aaa;
    border-bottom: 1px solid #ccc;
}

    .fileDBNavigationWrapper #loaderWrapper {
        margin-top: 150px;
    }

.fileDBNavigation {
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
    font-size: 0.8rem;
    line-height: 0.8rem;
}

    .fileDBNavigation a:hover {
        background-color: #f9f9f9;
    }

    .fileDBNavigation.sub {
        margin: 0;
        border-bottom: 0;
    }

        .fileDBNavigation.sub a {
            padding-left: 25px;
        }

.sub .fileDBNavigation.sub a {
    padding-left: 40px;
}

#fileDBContentNavigation {
    overflow: hidden;
    margin: 0;
    padding: 0;
}

.fileDBNavigation.sub .current {
    background: #f9f9f9;
}

.fileDBNavigation a {
    display: block;
    padding: 10px;
    border-top: 1px solid #ccc;
    color: rgba(0,0,0,0.75);
}

.fileDBNavigation [class^="icon-"], .fileDBNavigation [class*=" icon-"] {
    color: rgba(0,0,0,0.55);
    margin-right: 5px;
}

.fileDBDashboardUtilityBelt {
    min-height: 100px;
    padding: 20px;
    border-bottom: 1px solid #ddd;
}

.fileDBDashboard {
    float: left;
    width: 85%;
}

.fileDBContentNavigationItemLink {
    display: block;
    padding: 10px;
    border: 1px solid #ddd;
    height: 190px;
    width: 212px;
    margin: 20px 0 0 20px;
    background-color: #efefef;
}


.fileDBContentNavigationItem {
    overflow: hidden;
    float: left;
}


.fileDBContentNavigationItemLink:hover {
    background-color: #f9f9f9;
}

.fileDBContentNavigationItem .imageWrapper {
    display: block;
    background-repeat: no-repeat;
    width: 100%;
    height: 130px;
    overflow: hidden;
    border: 1px solid #ddd;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #fff;
}

.fileDBContentNavigationItem:nth-child(4n+1) .fileDBContentNavigationItemLink, .fileDBContentNavigationItem:first-child .fileDBContentNavigationItemLink {
    margin-left: 0;
}

.fileDBContentNavigationItem .imageWrapper .image {
}

.fileDBContentNavigationItem .imageWrapper [class^="icon-"], .fileDBContentNavigationItem .imageWrapper [class*=" icon-"] {
    margin: 20px auto;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 5rem;
}

.fileDBContentNavigationItem .fileName {
    line-height: 27px;
    margin: 0 0 -5px;
}

.fileDBContentNavigationItem p {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #222;
    font-size: 12px;
}


#fileDBContentNavigation .fileName {
    display: block;
}

.fileDBContentNavigationItem .lastEditDate {
    font-size: 0.75rem;
    color: #888;
    margin-top: 0px;
    display: block;
}

.fileDBNavigation .selected {
    background: #ededed;
}

.fileDBDashboardUtilityBelt .buttonWrapper {
    float: left;
    margin-right: 10px;
}

.fileDBDashboardUtilityBelt button.secondary, .fileDBDashboardUtilityBelt button.primary {
    margin-bottom: 0;
}

.fileDBDashboardUtilityBelt {
    overflow: hidden;
}

.fileDBNavigation.sub {
    display: none;
}

.uploadImagethumb {
    position: absolute;
    left: 50%;
    display: block;
    height: 150px;
    width: 200px;
    margin-left: -100px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

#fileDBDashboardDetail {
    padding: 20px;
}

#cropperWrapper {
    border: 1px solid #ddd;
    height: 438px;
    width: 75%;
    float: left;
}

#cropperThumbWrapper {
    width: 25%;
    float: left;
    padding-left: 30px;
}

.cropper-thumbPreviewWrapper {
    padding: 10px;
    border: 1px solid #ddd;
}

.cropper-thumbPreview {
    min-height: 100px;
    margin: auto;
}

#cropperThumbWrapper .description {
    padding: 0;
    margin: 0;
}

#croppingSection {
    overflow: hidden;
}

#fileMetaWrapper {
    width: 960px;
    margin-top: 40px;
    overflow: hidden;
}

.fileDBLinkToFile {
    display: block;
    font-size: 0.8rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
}

.fileDBContentNavigationItemLink.selected {
    background: #c4f2c9!important;
}

#imageDisplay {
    text-align: center;
    margin-bottom: 0;
    padding: 10px;
    border: 1px dashed #ddd;
}

    #imageDisplay img {
        max-width: 100%;
    }

.imageDetailsWrapper {
    border: 1px dashed #ddd;
    border-top: 0;
    padding: 10px;
    background: #fff;
    margin-bottom: 10px;
}

    .imageDetailsWrapper table {
        margin: 0;
    }

        .imageDetailsWrapper td, .imageDetailsWrapper table, .imageDetailsWrapper table tr:nth-of-type(even) td {
            background: none;
            border: 0;
            padding: 5px 0;
        }

.imageInfoHeader {
    font-weight: 700;
    width: 100px;
}

#mediaQuerySearch {
    margin-top: 22px;
}

/* FILEDB - END */


/*  MODAL - START  */
.modalHeader {
    background: #0f6481;
    padding: 0.9375em 0.9375em 0;
}

p.modalHeader {
    background: none;
    padding: 0px;
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

p.modalSubHeader {
    border-bottom: 1px solid #aaa;
    padding: 0;
    font-size: 14px;
}

.modalDescription {
    margin: 0;
    font-size: 12px;
    padding-top: 5px;
    color: #666;
    line-height: 10px;
}

ul.tabNavigation {
}

.tabsNavigationItem {
    float: left;
}

.tabsNavigationItemLink {
    display: block;
    text-decoration: none;
    font-family: Arial;
    font-size: 1em;
    padding: 10px 30px;
    margin-left: 20px;
    background: #12779a;
    color: #fff;
    -webkit-border-top-left-radius: 5px;
    -webkit-border-top-right-radius: 5px;
    -moz-border-radius-topleft: 5px;
    -moz-border-radius-topright: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

    .tabsNavigationItemLink:hover {
        background: #298ea9;
        color: #fff;
    }

.tabsNavigationItem.first a {
    margin-left: 0;
}

.tabsNavigationItemLink.active {
    background-color: #fff;
    color: #0a234c;
}

.modalFooter {
    width: 100%;
    position: absolute;
    bottom: 0;
    min-height: 60px;
    background: #aaa;
    border-top: 1px solid #d6d8e1;
    text-align: right;
    padding: 0.9375em;
}

    .modalFooter button {
        margin-bottom: 0;
    }

.modalBody {
    margin: 25px 0 80px;
}

#courseEditModal {
    overflow: hidden;
}

.modalContractStatus {
    float: right;
    font-size: 14px;
    font-weight: 700;
}

.modalContractEquipment {
    min-height: 150px;
}

.attachmentDate {
    text-align: right;
}
/*  MODAL - END  */

/* Comments - START */
#commentslist {
    border-top: 1px solid #ddd;
}

.commentItem {
    /*border-radius: 5px;*/
    /*background: #eee;*/
    padding: 10px;
    margin-bottom: 3px;
    min-height: 60px;
    border-bottom: 1px solid #ddd;
}

    .commentItem.even {
        background: #f0f0f0;
    }

    .commentItem.status.attention {
        background-color: #eeeeca;
    }

    .commentItem.status.error {
        background-color: #eecaca;
    }


    .commentItem p {
        margin-bottom: 0;
    }

    .commentItem p, .commentItem a {
        font-size: 0.8em;
    }

.dashboardViewAllButton {
    display: block;
    text-align: right;
    font-size: 14px;
}

#latestComments {
    border-top: 1px solid #ddd;
}

    #latestComments .commentItem {
        border-left: 1px solid #ddd;
        border-right: 1px solid #ddd;
        margin-bottom: 0;
    }

    #latestComments a {
        font-size: 1rem;
        display: block;
    }

.primaryComment {
    overflow: hidden;
}

.authorContent {
    float: left;
    padding-right: 20px;
    width: 175px;
    overflow: hidden;
}



.commentAddCommentLink {
    float: right;
    display: block;
    margin-right: 10px;
}

.commentContent {
    float: left;
    margin-left: 20px;
    padding-left: 20px;
    border-left: 1px solid #aaa;
}

    .commentContent.withReference {
        float: none;
        overflow: hidden;
        margin-top: 2px;
    }

.commentItemDate {
    float: right;
    display: block;
    min-width: 90px;
    text-align: right;
    color: #333;
    overflow: hidden;
}

.commentItem a {
    overflow: hidden;
}

.commentAuthor {
    font-weight: 700;
    float: left;
    margin-right: 20px;
    display: block;
    color: #333;
    width: 140px;
}

.commentContent {
    color: #333;
}

    .commentContent .commentContent {
        width: 240px;
    }

#newComment {
    margin-top: 10px;
}

.replyWrapper {
    border-top: 1px dashed #ccc;
    margin-top: 10px;
    overflow: hidden;
    padding-top: 10px;
}

    .replyWrapper button {
        margin-bottom: 0;
    }

    .replyWrapper textarea {
        height: 40px;
        resize: vertical;
        margin-bottom: 0;
    }

.nestedComments {
    border-top: 1px dashed #ccc;
    margin: 10px 0 0;
}

.nestedCommentsItem {
    overflow: hidden;
    padding: 10px;
    background-color: #f4f4f4;
    margin-bottom: 1px;
}

.nestedComments.hidden {
    display: none;
}

.lastestCommentsItem {
    display: block;
    overflow: hidden;
}

.commentLocation {
    display: block;
}


/* Location Detail - START */
#termLabel {
    margin-bottom: 0;
}

.advancedSearchWrapper {
    margin: 20px 0 30px;
    padding: 20px 0;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

#locationActivitylist {
    border-top: 1px solid #ddd;
}

.locationActivitylistItem {
    border-bottom: 1px solid #ddd;
}

    .locationActivitylistItem a {
        display: block;
        overflow: hidden;
        padding: 10px;
    }

.locationActivitylistStartDate {
    float: left;
}

.locationActivitylistName {
    float: left;
    margin-left: 20px;
}

/* Comments - END */

.currentItemDataHolder h2.rightColumnHeader, h2.rightColumnHeader {
    background: transparent;
    border-top: 0;
    font-size: 1rem;
    padding: 5px 0;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
}

.locationRelatedLink {
    font-size: 0.9rem;
    padding: 8px;
}

.listColumnPickerItem {
    margin-bottom: 5px;
}

.filterContainer {
    position: absolute;
    background: #e5e5e5;
    padding: 10px;
    z-index: 100;
    width: 205px;
    border-top: 1px solid #aaa;
}

#galleriHolder {
    min-height: 50px;
}

.productDetailExpenseslist {
    border-top: 1px dashed #ddd;
    margin-top: 10px;
}

.productDetailExpensesItem {
    border-bottom: 1px dashed #ddd;
    overflow: hidden;
    padding: 5px 0;
}

.productDetailExpensesItemPrice {
    float: right;
}

.productDetailExpensesItem p {
    font-size: 0.75rem;
}



#addExpense {
    float: left;
    min-width: 100px;
    width: 100px;
    margin: 10px 0 0;
    padding: 5px;
}

#expensesTotal {
    padding-top: 10px;
}

.remunerationDate {
    display: block;
}

.remunerationAmount {
    text-align: right;
}

.remunerationItem {
    font-size: 0.85rem;
    border-top: 1px solid #ddd;
    overflow: hidden;
    padding: 5px 0;
}

    .remunerationItem.even {
        background-color: #f9f9f9;
    }

button.addRemuneration {
    float: right;
    width: auto;
    margin: 0;
}

.listHeader {
    padding: 10px;
    margin: 0;
}

    .listHeader.price {
        text-align: right;
    }

/* Equipment - START */
#equipmentCreate {
    margin-top: 20px;
}

#equipmentlist .equipmentlistItem.inactive a {
    text-decoration: line-through;
    color: rgba(0,0,0,0.5);
}
/* Equipment - END */

/* USER Detail */
.userOrderslistLabelHolder {
    overflow: hidden;
    font-weight: 700;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.userOrderslist li {
    border-bottom: 1px solid #ddd;
}

    .userOrderslist li a {
        display: block;
        overflow: hidden;
    }

.userOrderslistColumn {
    float: left;
    padding: 0 10px;
}

    .userOrderslistColumn.orderId {
        padding-left: 0;
        width: 20%;
    }

    .userOrderslistColumn.lines {
        width: 15%;
        text-align: center;
    }

    .userOrderslistColumn.orderDate {
        width: 35%;
    }

/* Productlist - MASS EDIT */
#massPublishTableWrapper {
    max-height: 500px;
    overflow: auto;
}

.selectedProductsDate {
    text-align: right;
    white-space: nowrap;
}

.visibleId {
    white-space: nowrap;
}

/* Productlist - MASS EDIT - END */
/* OrderDetail - REIMBURSE */
.price {
    text-align: right;
}


.optionTitle {
    font-size: 14px;
    margin: 0;
}

.optionDescription {
    font-size: 12px;
    margin: 0;
    color: #aaa;
}

/* OrderDetail - REIMBURSE - END */

/* USER Detail end */


/* Keyaccount - Contract */
.keyAccountsContractValidatelist {
    margin-top: 20px;
    border-bottom: 1px solid #ddd;
}

    .keyAccountsContractValidatelist li {
        padding: 5px;
        border-top: 1px dashed #ddd;
        overflow: hidden;
    }

        .keyAccountsContractValidatelist li p {
            font-size: 0.75rem;
        }

.keyAccountContractProductsBefore {
    text-decoration: line-through;
    color: #aaa;
}

.keyAccountContractProductsDelete {
    width: 32px;
}

.keyAccountsContractValidatelistField {
    float: left;
    width: 90%;
}

.keyAccountsContractValidatelistDecline {
    float: left;
    width: 10%;
    text-align: right;
    padding: 10px 0;
}
/* Keyaccount - Contract END */
/* Keyaccount - Contract create modal */
#contractDescription {
    min-height: 250px;
}
/* Keyaccount - Contract create modal - END */
/* Keyaccount - Add product til contract modal */
#keyaccountContractProductSearch {
    margin-top: 27px;
    margin-bottom:0;
}

#keyaccountContractProductSearchResultWrapper {
    height: 450px;
    overflow: auto;
}

.keyAccountContractTableSumUp {
    text-align: right;
    font-style: italic;
    color: #aaa;
    font-size: 0.75rem;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #ddd;
}
/* Keyaccount - Add product til contract modal - END */

/* KeyAccount Contract Orders */
.keyAccountContractListItem {
    border: 1px solid #aaa;
    padding: 10px 10px 0 10px;
    margin-bottom: 20px;
}


/* DASHBOARD */
.noWrap {
    white-space: nowrap;
}

#dashboardContracts {
    float: right;
    width: 300px;
}

.contractlistProductName {
    width: 275px;
}

.contractlistProductVisibleId {
    width: 90px;
}

.approvelistProductName {
    width: 350px;
}

.tableNoMatch {
    text-align: center;
    padding: 10px;
    font-style: italic;
}

.contractlistfileUploadDate {
    text-align: right;
}

table tr.optionItem:nth-of-type(even) td {
    background-color: #fff;
}


table tr.optionItem:nth-of-type(even):hover td {
    background-color: #D6F2FA;
}

table tr.attenanceItem td {
    background-color: #f0f0f0;
}

.optionListSequenceNo {
    text-align: center;
}

.attendanceListSequenceNo, .optionListQuantity {
    text-align: center;
}

.optionDescription {
    color: #A9A9A9;
    display: block;
}

.attendanceListOrderNr {
    width: 100px;
    text-align: center;
}

.attendanceListPhoneNumber {
    width: 120px;
    text-align: center;
}

.attendanceListLink {
    display: block;
    margin: 0 0 20px;
}

    .attendanceListLink.selected {
        color: #333;
        text-decoration: underline;
    }

.participantlistItemName {
    width: 20%;
    min-width: 250px;
}

.participantlistItemQuantity {
    width: 5%;
    text-align: right;
}

.participantlistItemPrice {
    width: 10%;
}

.attenanceItem.disabled td, .optionItem.disabled td ,#attendancelist .itemRow.disabled{
    text-decoration: line-through;
    color: #a0a0a0;
}

#attendancelist .itemRow.disabled td{
    color:#a0a0a0
}
#attendancelist .itemRow.disabled a{
    color:#91B5C1
}




#productTypeTable td {
    line-height: 27px;
}

#productTypeTable .deactivated td, #productTypeTable .deactivated a {
    color: #aaa;
    text-decoration: line-through;
}

#productTypeTable .createProductTypeChild {
    float: right;
    padding: 2px;
}

    #productTypeTable .createProductTypeChild .icon-Create-icon {
        color: #12779a;
        font-size: 1.25rem;
    }

.eventContractRow {
    overflow: hidden;
}

.courseEventAddedEmployeePayRollTypeWrapper {
    clear: both;
    margin-top: 20px;
}

.courseEventContractPayrollSelector {
    background-color: #fff;
    margin-bottom: 10px;
}

.courseEventContractAttachmentItem {
    border: 1px solid #ddd;
    margin-bottom: 20px;
    min-height: 250px;
    font-size: 14px;
}

.courseEventContractAttachmentHeader {
    background: #eaeaea;
    padding: 10px 0;
    overflow: hidden;
}


    .courseEventContractAttachmentHeader .title {
        color: #333;
        font-size: 16px;
        width: 100%;
        display: block;
        font-weight: 700;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        line-height: 20px;
    }

#contractsStatus {
    overflow-x: hidden;
    width: 100%;
}

    #contractsStatus.shortlist {
        max-height: 350px;
    }

.courseEventContractAttachmentHeader .type {
    font-size: 14px;
    margin-top: 5px;
}

.courseEventContractAttachmentHeader p {
    margin: 0;
}

.courseEventContractAttachmentHeader span {
    display: block;
}


.courseEventContractAttachmentApproveBtn, .courseEventContractAttachmentApprove {
    text-align: right;
}

    .courseEventContractAttachmentApprove * {
        cursor: default;
    }

.courseEventContractAttachmentHeader .uploadDate {
    font-size: 12px;
    color: #555;
    text-align: right;
    margin: 5px 0;
    line-height: 14px;
}

.courseEventContractAttachmentHeader .currentStatusChangeText {
    text-align: right;
    font-size: 12px;
}



.courseEventContractAttachmentHeader .status-text {
    font-weight: 700;
}

.courseEventContractAttachmentContent .attachmentLine {
    overflow: hidden;
}

    .courseEventContractAttachmentContent .attachmentLine label {
        float: left;
        display: block;
        padding: 5px 10px;
        margin: 0;
    }

    .courseEventContractAttachmentContent .attachmentLine a, .courseEventContractAttachmentContent .attachmentLine p {
        display: block;
        float: right;
        font-size: 14px;
        padding: 5px 10px;
        margin: 0;
    }

        .courseEventContractAttachmentContent .attachmentLine p.description {
            text-align: left;
            width: 100%;
            font-style: normal;
        }

th.sortable:hover {
    background: #f5f5f5;
}

th.sortable {
    color: #12779a;
    cursor: pointer;
}

    th.sortable.selected {
        text-decoration: underline;
        background: #eee;
        border-left: solid 1px #ddd;
        border-right: solid 1px #ddd;
        font-weight: 700;
    }

#proofStatus {
    cursor: pointer;
}

p.attachmentStatusTitle {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 10px;
}

#attachmentDetail #description {
    min-height: 150px;
}

.datePickBtnNoWrapper {
    position: absolute;
    right: 20px;
    bottom: 40px;
}

#contractModalAttachmentGeneral {
    border-bottom: 1px solid #ddd;
    margin-bottom: 20px;
    padding-bottom: 10px;
}

    #contractModalAttachmentGeneral .checkboxLabel, #contractModalAttachmentGeneral input[type="checkbox"] {
        float: left;
    }

    #contractModalAttachmentGeneral input[type="checkbox"] {
        position: relative;
        top: 3px;
        margin-right: 0px;
    }

.CEL_employeeItemContract [class^='icon-'] {
    margin-left: 5px;
}

.statusTopBox {
    padding: 10px;
    font-weight: 700;
}
#keyAccountActiveContracts td{
    background:#fff;
}
#keyAccountActiveContracts td.spacer{
    height:10px;
    background-color:#fff;
}

.sumupLine td{
    border-top:2px solid #ddd;
}

#keyAccountActiveContracts .keyAccountCompanyTitleWrapper{
   background-color:#f8f8f8;
   border-bottom:1px solid #aaa;
}

#waitlistDeleteSelected{float:left;width:auto;}
#productDetailRelatedProductSearch{
  margin-top: 28px;
  padding-bottom: 10px;
  margin-bottom: 0;
  }

#productRelatedProductsTable tr:first-child .relatedProductsMoveItemUp, #productRelatedLinksTable tr:first-child .relatedLinkMoveItemUp{
    display:none;
}

#productRelatedProductsTable tr td:last-child{
    padding-left:0;
     width:55px;
}

.relatedProductsMoveItemUp, .relatedLinkMoveItemUp{
    padding:12px;
    float:right;
}


.relatedLinkEdit{
    float:right;
    padding:10px;
}
.productDetailRelatedTitle{
    /*width:60%;*/
}

#employeeDetailStatusMsg{
    width:75px;
}

.attendancelistOption{
    margin-top:10px;
    width:300px;
    margin-bottom:0;
    border-top:1px dashed rgba(0,0,0,0.2);

}

    .attendancelistOption:first-child{
         margin-top:0;
         border-top:0;
    }
    .attendancelistOption td{
         border-bottom:0;
         border-top:0;
    }

.amountColumn {
        width:50px;
        text-align:right;
        vertical-align:top;
    }

#attendancelistWithOptions td{vertical-align:top;}

#employeeOutOfDraftBtn {
    max-width:160px;
    margin:20px auto 0;
}

.doubleRelationWrapper{
      margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #ddd;
}

.doubleRelationWrapper .description {
    margin-left:25px;
}

.doubleRelationInfo {
  display: block;
  font-size: 12px;
  color: #444;
}

td.contractlistRemuneration, th.contractlistRemuneration{
    text-align:right;
}

textarea.large{min-height:150px}
textarea.larger{min-height:400px}
/*#educatorsMails,#participantsMails{white-space: nowrap;}*/


.keyAccountAmount, .keyAccountSales {
    text-align:right;
}
.keyAccountRegistrationStart , .keyAccountPublishedFrom, .keyAccountPublishedTo {
    text-align:center;
}

.keyAccountAmount,.keyAccountRegistrationStart , .keyAccountPublishedFrom , .keyAccountSales, .keyAccountPublishedTo  {
    white-space:nowrap;
}


.keyAccountMaxProducts, .keyAccountMaxQuantity {
    text-align: center;
}
#productSearchTable .primarySearchSumRow td {
    border-top: 2px solid black;
    padding-top: 10px;
    padding-bottom: 10px;
}
.invoiceSearch{
    border-top:1px dashed #ddd; 
    border-bottom:1px dashed #ddd;
    margin-bottom:20px;
    padding-top:15px;
}
#invoiceSearchBtn, #invoiceSearchUse{
    margin-top:27px;
}

#employeeContracts th{
    color:#12779a;

}



/* EDITOR PROPERTIES - PLEASE DON'T DELETE THIS LINE TO AVOID DUPLICATE PROPERTIES */
